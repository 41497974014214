const blockCareers = async($block) => {
	const { gsap } = await import( 'gsap' );
	const { ScrollTrigger } = await import( 'gsap/ScrollTrigger' );
	gsap.registerPlugin( ScrollTrigger );

	if ($('.wp-site-blocks').length) {
		gsap.to( $block.find('.background-img'), {
			y: '18%',
			ease: 'none',
			scrollTrigger: {
				scroller: '.wp-site-blocks',
				trigger: $block,
				start: 'top bottom',
				end: 'bottom top',
				scrub: true
			}
		} );
	}
};

themeUtils.loadBlock( blockCareers, 'careers', '.section-careers' );
